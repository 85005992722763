<template>
    <div class="icon-picker">
        <div
            v-if="isModalOpen"
            @click.self="isModalOpen = false"
            class="overlay"
        >
            <div class="picker-modal">
                <div
                    class="icon-container"
                    v-for="icon in defineIcons()"
                    :key="icon"
                >
                    <icon-selector
                        class="icon"
                        @click.native="selectIcon(icon)"
                        :icon="icon"
                    />
                    <span>{{ icon }}</span>
                </div>
                <div
                    class="icon-container"
                    @click="selectIcon('')"
                >
                    <span></span>
                    <span>None</span>
                </div>
            </div>
        </div>

        <div
            class="picker-button"
            @click="toggleModal"
        >
            <icon-selector :icon="selectedIcon" />
            {{ selectedIcon || "No icon selected" }}
        </div>
    </div>
</template>

<script>
import IconSelector from "./IconSelector.vue";

export default {
    name: "IconPicker",

    components: {
        IconSelector,
    },

    props: {
        selectedIcon: {
            type: String,
            required: false,
        },
        includedIcons: {
            type: Array,
            require: false,
        },
        excludedIcons: {
            type: Array,
            require: false,
        },
    },

    data: () => ({
        isModalOpen: false,
        iconList: [
            "Account",
            "Article",
            "Attendance",
            "Audio",
            "Audio 2",
            "Ball",
            "Basket",
            "Blue Sky",
            "Burger",
            "Calendar",
            "Calendar 2",
            "Camera",
            "Chevron Left",
            "Chevron Right",
            "Close",
            "Copy Link",
            "Country",
            "Download",
            "Email",
            "Email Filled",
            "External Link",
            "Facebook",
            "Feed",
            "FullTime",
            "Hospitality",
            "Info",
            "Instagram",
            "Lineups",
            "Linkedin",
            "Live",
            "Match",
            "Microphone",
            "Notification",
            "Padlock",
            "Phone",
            // 'Play',
            "Player",
            "Plus",
            "Position",
            "Quiz",
            "Refine",
            "Refresh",
            "Scores",
            "Search",
            "Share",
            "Shirt",
            "SignIn",
            "Snapchat",
            "Spotify",
            "Star",
            "Stats",
            "Streamline",
            "Subscription",
            "Tick",
            "Tickets",
            "Tiktok",
            "Threads",
            "Twitter",
            "X",
            "Video",
            "Video Camera",
            "Weibo",
            "Whistle",
            "Youtube",
        ],
    }),

    methods: {
        toggleModal() {
            this.isModalOpen = !this.isModalOpen;
        },

        defineIcons() {
            if (this.includedIcons) {
                return this.includedIcons;
            } else if (this.excludedIcons) {
                return this.iconList.filter(
                    (icon) => this.excludedIcons.indexOf(icon) === -1
                );
            } else {
                return this.iconList;
            }
        },

        selectIcon(icon) {
            this.$emit("iconSelected", icon);
            this.isModalOpen = false;
        },
    },
};
</script>

<style scoped lang='scss'>
.icon-picker {}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.picker-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(52px, 1fr));
    grid-gap: 10px;
    width: 50%;
    min-width: 300px;
    max-width: 650px;
    z-index: 999;
    background: #eee;
    border-radius: 4px;
}

.picker-button,
.icon-container {
    cursor: pointer;
    width: 48px;
    height: 48px;
    background: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-size: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba($color: #000000, $alpha: 0.6);
    text-align: center;
    padding: 4px 2px 2px;
    line-height: 1;

    svg {
        margin-bottom: 4px;
        color: black;
    }
}
</style>

